<template>
  <form-field :name="field.name">
    <v-text-field
      v-model="field.value"
      :loading="loading"
      :prepend-icon="prependIcon"
      v-bind="field.props"
      clearable
      box
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'
import { createGetParams } from '@/rest'

export default {
  mixins: [formField],
  data () {
    return {
      error: null,
      hint: '',
      input: null,
      loading: false,
    }
  },
  computed: {
    prependIcon: function () {
      return 'mdi-account-tie'
    },
    validDealer: function () {
      this.validateDealer()
      return this.error === null ? true : this.error
    },
  },
  watch: {
    'field.value': function (val) {
      this.error = 'Unverified!'
      if (val) this.field.value = val.toUpperCase()
      this.field.props.hint = this.hint
    },
  },
  mounted: function () {
    this.field.props.rules.push(val => this.validDealer)
    Object.assign(this.field.props, { validateOnBlur: true })
    if (this.hint.length === 0) {
      this.hint = this.field.props.hint === undefined || this.field.props.hint === null ? '' : this.field.props.hint
    }
  },
  methods: {
    async validateDealer () {
      if (!this.field.value) return true
      const url = 'getDealerSubscription.php'
      const params = createGetParams({
        target: this.field.value,
      })

      this.loading = true
      try {
        const response = await this.$rest.get(url, params)
        this.loading = false
        if (this.field.props.claimer && this.field.props.claimer !== (response.data.onex ? 'onex' : 'xox')) {
          if (this.field.props.filterClaimer) {
            this.loading = true
            const response2 = await this.$rest.get('crossErechargeTransferAllowable.php', params)
            this.loading = false
            if (response2.data.allowable) {
              this.field.props.hint = response.data.name
              this.error = null
            } else {
              this.error = 'Cross-referrenced dealer!'
            }
          } else {
            this.error = 'Cross-referrenced dealer!'
          }
        } else {
          this.field.props.hint = response.data.name
          this.error = null
        }
      } catch (error) {
        this.loading = false
        this.field.props.hint = ''
        if (error.response) {
          if (error.response.status === 404) {
            this.error = 'No such Dealer!'
          } else {
            this.error = error.message
          }
        } else {
          this.error = error.message
        }
      }
      this.validate()
    },
  },
}
</script>
